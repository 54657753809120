.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-container:hover {
  cursor: pointer;
  background-color: #ededed;
}
