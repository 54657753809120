.container {
}

.upper-case-post::first-letter {
  text-transform: capitalize;
}

.container:hover {
  cursor: pointer;
}

.up-text {
  font-size: 12px;
  /* font-family: 'Inter', Fallback, serif; */
  color: #9d9d9d;
}

.up-text_medium {
  font-size: 15px;
  /* //   font-family: 'Inter', Fallback, serif; */
  color: #2443b2;
  font-weight: bold;
}

.up-container {
  background: #fbfbfd;
}

.up-container:hover {
  cursor: pointer;
  background: #ededed;
}

.action-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.action-container_row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.action-img {
  padding: 2px;
  height: 23px;
  width: 23px;
}

.action-more-img {
  padding: 2px;
  height: 20px;
  width: 15px;
}

.location-img {
  padding: 2px;
  height: 15px;
  width: 15px;
}
.location-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  margin-top: -16px;
}

/* Desktop styles */
@media (min-width: 1024px) {
  /* Your desktop styles here */
  .post-type-des {
    font-weight: 600;
    margin-left: 17px;
    font-size: 17px;
  }
}

/* Tablet styles */
@media (min-width: 768px) and (max-width: 1023px) {
  /* Your tablet styles here */
}

/* Mobile styles */
@media (max-width: 767px) {
  /* Your mobile styles here */
  .post_mobile_view {
    width: 315px;
  }
  .post-type-mob {
    font-weight: 600;
    margin-left: 15px;
    font-size: 14px;
    line-height: 14.52px;
  }
}
