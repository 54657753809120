.bottom-nav-bar {
    height: 48px;
    background: #d9d9d9;

    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    width: 100%;
}

.bottom-nav-bar-text {
    color: #f40;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
