.main-div-org {
  background: #fff;
  padding-top: 29px;
  overflow-x: hidden;
  margin-right: auto;
  margin-left: auto;
}

.margin-top {
  margin-top: 127px;
}

.image-size-org {
  height: 200px;
  width: 200px;
  border-radius: 200px;
  border: 1px solid #9d9d9d;
  object-fit: contain;
}

.image-size-plus {
  height: 24px;
  width: 24px;
}

.image-size-edit {
  height: 24px;
  width: 24px;
}

.edit-pencil-org-details {
  height: 24px;
  width: 24px;
}

.check-text-org {
  color: black;
  text-align: center;
  font-family: 'Inter';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.check-text-margin-top-org {
  margin-top: 0px;
}

.org-logo {
  margin-top: 20px;
}

.text-onboard {
  color: #fbfbfd;
  font-family: 'Inter';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  flex-shrink: 0;
}

.text-left {
  text-align: left;
}

.margin-top-button-section {
  margin-top: 40px;
}
@media screen and (max-width: 400px) {
  .middle-flex-mobile {
    width: 300px;
  }
  .mobile-flex-left {
    flex: 15;
  }
  .mobile-flex-center {
    flex: 70;
  }
  .mobile-flex-right {
    flex: 15;
  }
  .details-margin-mobile {
    margin-left: 25px;
  }
}

@media only screen and (min-device-width: 401px) and (max-device-width: 1024px) {
  .middle-flex-tab {
    width: 300px;
  }
  .tab-flex-left {
    flex: 30;
  }
  .tab-flex-center {
    flex: 40;
    align-items: flex-end;
  }
  .tab-flex-right {
    flex: 30;
    text-align: start;
  }
  .details-margin-tab {
    margin-left: auto;
    margin-right: auto;
  }
}

/* Desktop styles */
@media (min-width: 1024px) {
  .middle-flex-desktop {
    width: 300px;
  }
  .desktop-flex-left {
    flex: 43;
  }
  .desktop-flex-center {
    flex: 15;
    align-items: flex-end;
  }
  .desktop-flex-right {
    flex: 43;
    text-align: start;
  }
  .details-margin-desktop {
    margin-left: auto;
    margin-right: auto;
  }
}
