.main-div-edit-user-details {
  background: #fff;
  padding-top: 29px;
  overflow-x: hidden;
  width: 300px;
  margin-right: auto;
  margin-left: auto;
}

.check-text-edit-user-details {
  color: black;
  text-align: center;
  font-family: 'Inter';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.first-letter-capital {
  text-transform: capitalize;
}

.logo-edit-user-details {
  margin-top: 20px;
}

.image-size-edit-user-details {
  height: 200px;
  width: 200px;
  border-radius: 200px;
  border: 1px solid #9d9d9d;
}

@media screen and (max-width: 400px) {
  .edit-user-details-margin-mobile {
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (min-device-width: 401px) and (max-device-width: 1024px) {
  .edit-user-details-margin-tab {
    margin-left: auto;
    margin-right: auto;
  }
}

/* Desktop styles */
@media (min-width: 1024px) {
  .edit-user-details-margin-desktop {
    margin-left: auto;
    margin-right: auto;
  }
}
