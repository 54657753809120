.main-div-sign-up {
  background: #303655;

  padding-top: 127px;
  overflow-x: hidden;
}

.image-size-sign-up {
  height: 200px;
  width: 200px;
}

.check-text-sign-up {
  color: #fbfbfd;
  text-align: center;
  font-family: 'Inter';
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.check-text-margin-top-sign-up {
  margin-top: 13px;
}

.text-sign-up {
  color: #fbfbfd;
  font-family: 'Inter';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.text-left-sign-up {
  text-align: left;
}

.margin-top-button-section-sign-up {
  margin-top: 40px;
}

.middle-flex-desktop-sign-up {
  width: 300px;
}
