@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

/* WebKit Browsers (Chrome, Safari) */
.scrollbar-hide::-webkit-scrollbar {
  width: 0px; /* Hide scrollbar in Chrome/Safari */
}

.scrollbar-hide:hover::-webkit-scrollbar {
  width: 6px; /* Show scrollbar on hover */
}

.scrollbar-hide::-webkit-scrollbar-thumb {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.scrollbar-hide:hover::-webkit-scrollbar-thumb {
  opacity: 1;
}

/* Firefox */
.scrollbar-hide {
  scrollbar-width: none; /* Hide scrollbar in Firefox */
}

.scrollbar-hide:hover {
  scrollbar-width: auto; /* Show scrollbar on hover */
}

* {
  min-width: 0;
}

body {
  @apply bg-background;
}

body {
  margin: 0;
  /* font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Roboto', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* body::-webkit-scrollbar {
  width: 0px;
  background: transparent;
} */

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

@font-face {
  font-family: 'Inter';
  src:
    local('Inter'),
    url(../src/assets/fonts/Inter-Regular.ttf) format('truetype');
}

/* Firefox (uncomment to work in Firefox, although other properties will not work!)  */
/** {
  scrollbar-width: thin;
  scrollbar-color: #B8B8B8 #DFE9EB;
}*/

/* Chrome, Edge and Safari */
*::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}
*::-webkit-scrollbar-track {
  border-radius: 6px;
  background-color: #dfe9eb;
}

*::-webkit-scrollbar-track:hover {
  background-color: #d9d9d9;
}

*::-webkit-scrollbar-track:active {
  background-color: #d9d9d9;
}

*::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: #b8b8b8;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #9d9d9d;
}

*::-webkit-scrollbar-thumb:active {
  background-color: #9d9d9d;
}
