.container {
}

.container:hover {
  cursor: pointer;
}

.up-text {
  font-size: 12px;
  font-family: 'Inter', Fallback, serif;
  color: #9d9d9d;
}

.up-container {
  background: #fbfbfd;
}

.up-container:hover {
  cursor: pointer;
  background: #ededed;
}

.action-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.action-img {
  padding: 2px;
  height: 23px;
  width: 23px;
}

.action-more-img {
  padding: 2px;
  height: 20px;
  width: 15px;
}
