.text_small {
  font-size: 14px;
  font-family: 'Inter', Fallback, serif;
  user-select: none;
}

.text_header {
  font-size: 20px;
  user-select: none;
  font-family: 'Inter', Fallback, serif;
}

.text_header_bold {
  font-size: 20px;
  font-weight: bold;
  user-select: none;
  font-family: 'Inter', Fallback, serif;
}

.react-datepicker-wrapper input[type='text'] {
  border: none;
  background-color: transparent;
  width: 100px;
  font-family: 'Inter', Fallback, serif;
}

.react-datepicker-wrapper:hover input[type='text'] {
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .second-div-mobile {
    display: flex;
    flex-direction: column;
    background: #fbfbfd;
    width: 100%;
    padding: 5px;
    border-radius: 6px;
    text-align: center;
    padding-right: 15px;
    padding-left: 15px;
  }
  .div-detail-box-mobile {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
}

/* Desktop styles */
@media (min-width: 1024px) {
  /* Your desktop styles here */
  .second-div-des {
    display: flex;
    flex-direction: column;
    background: #fbfbfd;
    width: 100%;
    max-width: 1150px;
    padding: 60px;
    border-radius: 6px;
    margin: 60px;
    text-align: start;
  }
  .div-detail-box-des {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-right: 50px;
  }
}
