.option {
  font-size: 22px;
}

.option:hover {
  cursor: pointer;
}

.img-button {
  padding: 5px;
  border-radius: 3px;
}

.img-button:hover {
  cursor: pointer;
  background-color: #ededed;
}
