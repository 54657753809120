.main-div-delete-confirm {
  position: absolute;
  z-index: 9000;
  background: white;
  margin-top: 50px;
  border-radius: 6px;
}

.button-section-delete-confirm {
  display: flex;
  flex-direction: row;
  /*justify-content: space-around;*/
  /*margin-bottom: 200px;*/
  /*margin-top: 10px;*/
}
