.main-div-edit-org {
  background: #fff;
  padding-top: 29px;
  overflow-x: hidden;
  width: 300px;
  margin-right: auto;
  margin-left: auto;
}

.check-text-edit-org {
  color: black;
  text-align: center;
  font-family: 'Inter';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.logo-edit-edit-org {
  margin-top: 20px;
}

.avatar-text-width-edit-org {
  width: 73px;
}
