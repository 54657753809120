.heading-text {
  color: #000;
  /* font-family: Inter; */
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.value-text {
  color: #000;
  /* font-family: Inter; */
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.table-data {
  vertical-align: text-top;
  table-layout: fixed;
}

.table-header {
  text-align: start;
  table-layout: fixed;
  width: 130px;
}
