.topic-header {
  border-bottom: 1px solid #d9d9d9;
  display: flex;
  align-items: center;
  justify-content: left;
}

.mobile-filter-button {
  position: absolute;
  right: 25px;
  bottom: 25px;
  z-index: 10;
}

.mobile-filter-button_open {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 999;
}

@media screen and (max-width: 767px) {
  .topic_detail_box_mobile {
    margin-right: auto;
    margin-left: auto;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1500px) {
  .topic_detail_box_tab {
    margin-right: auto;
    margin-left: auto;
  }
}

/* Desktop styles */
@media (min-width: 1024px) {
  /* Your desktop styles here */
}
