.button-style-on-board {
  border-radius: 20px;
  background-color: #d9d9d9;
  color: #000000;
  height: 29px;
  text-transform: none;
  font-family: Inter;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
}

.flex-style-on-board {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  cursor: pointer;
}
