#HomeNewsFeedMobileAndTab_root::-webkit-scrollbar {
  display: none;
}

@media screen and (max-width: 767px) {
  .home_newsfeed_mobile_view {
    margin-left: -20%;
  }
}

/* ipad */
@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) {
  .home_newsfeed_ipad_view {
    margin-left: -21%;
  }
}
