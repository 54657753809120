.main-div {
  background: #303655;
  padding-top: 127px;
  overflow-x: hidden;
}

.margin-top {
  margin-top: 127px;
}

.image-size {
  height: 200px;
  width: 200px;
}

.check-text {
  color: #fbfbfd;
  text-align: center;
  font-family: 'Inter';
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.check-text-margin-top {
  margin-top: 13px;
}

.text-onboard {
  color: #fbfbfd;
  font-family: 'Inter';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  flex-shrink: 0;
}

.text-left {
  text-align: left;
}

.margin-top-button-section {
  margin-top: 40px;
}
@media screen and (max-width: 767px) {
  .middle-flex-mobile {
    width: 300px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .middle-flex-tab {
    width: 300px;
  }
}

/* Desktop styles */
@media (min-width: 1024px) {
  .middle-flex-desktop {
    width: 300px;
  }
}
