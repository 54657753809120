.post-type-font-pcc {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.topic-location-text-pcc {
  color: #000;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.channel-picker-wrapper-pc {
  max-height: 300px;
  z-index: 10;
  overflow-x: scroll;
}

.text-length-pc {
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.image-styles-pc {
  border-radius: 8px;
}

.flex-class-post-create-content {
  display: flex;
}

.flex-row-post-create-content {
  flex-direction: row;
}

.flex-column-post-create-content {
  flex-direction: column;
  align-items: flex-end;
}

.flex-left-twenty {
  flex: 10;
}

.flex-right-twenty {
  flex: 10;
}

.flex-eighteen {
  flex: 80;
}

.justify-align-center {
  justify-content: center;
  align-items: center;
  display: flex;
}

.overlay-div-post-create-content {
  border-radius: 6px;
  border: 1px solid #d9d9d9;
  background: #fbfbfd;
  text-align: start;
  width: 270px;
  position: fixed;
  z-index: 200000;
  padding-bottom: 20px;
  padding-right: 10px;
  margin-top: -12px;
  margin-left: 15px;
}

.padding-left-post-create-content {
  padding-left: 30px;
}
