.body-text-style-add-member {
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

#overlay {
  margin-left: 5px;
}

.main-div-add-member {
  z-index: 300;
  max-width: 100%;
  overflow-x: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000000bb;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.second-main-div {
  margin-top: 50px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  background: #fbfbfd;
  max-width: 350px;
 /*// height: 340px;*/
  padding: 19px;
}

.flex-style-heading {
  display: flex;
  align-items: flex-start;
}

@media screen and (max-width: 767px) {
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
}

/* Desktop styles */
@media (min-width: 1024px) {
}

label {
  display: flex;
  position: relative;
}

.input-for-search-user-add-member {
  width: 313px;
  border-radius: 6px;
  border-style: solid;
  border-color: #d9d9d9;
  border-width: 1px;
  background-color: #fbfbfd;
  padding: 10px;
  font-size: 16px;
  text-overflow: ellipsis;
  color: black;
}
.input-for-search-user-add-member::placeholder {
  color: #d9d9d9;
  font-family: Inter;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
}

.input-for-search-user-add-member:focus {
  color: black;
}

#icon {
  position: absolute;
  right: 12px;
  top: 8px;
  width: 24px;
  height: 24px;
}

.drop-down-list-style-add-member {
  background: #fbfbfd;
  border-bottom-color: #d9d9d9;
  border-left-color: #d9d9d9;
  border-right-color: #d9d9d9;
  border-width: 1px;
  border-top-color: transparent;
  border-style: solid;
  position: relative;
  width: 310px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  z-index: 100;
}

.drop-down-hover-list-style-add-member:hover {
  background: #d9d9d9;
}

.drop-down-role-div {
  display: flex;
  flex-direction: row;
  justify-content: start;
  padding-bottom: 5px;
  margin-top: 10px;
}

.role-add-drop-down-add-member {
  width: 310px;
  border-radius: 6px;
  border-style: solid;
  border-color: #d9d9d9;
  border-width: 1px;
  background-color: #fbfbfd;
  padding: 10px;
  font-size: 16px;
  height: 35px;
  text-overflow: ellipsis;
  color: black;
}

.role-add-drop-down-add-member::placeholder {
  color: #d9d9d9;
  font-family: Inter;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
}

.role-add-drop-down-add-member:focus {
  color: black;
}

.make-bold-letter {
  font-weight: bold;
}

.top-margin-add-member {
  margin-top: 100px;
}

.drop-down-list-add-member-style {
  background: #fbfbfd;
  border-bottom-color: #d9d9d9;
  border-left-color: #d9d9d9;
  border-right-color: #d9d9d9;
  border-top-color: transparent;
  border-style: solid;
  border-width: 1px;
  position: fixed;
  overflow: scroll;
  float: right;
  width: 312px;
  z-index: 100000;
  height: auto;
  max-height: 180px;
  overflow-y: scroll;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.profile-main-add-member {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  padding-top: 10px;
  align-items: center;
  z-index: 90000;
}

.profile-picture-add-member {
  height: 40px;
  width: 40px;
  border-radius: 50px;
  border: 1px solid #000000;
}

.name-add-member {
  font-weight: bold;
  font-family: 'Inter', Fallback, serif;
  text-align: start;
}

.location-add-member {
  font-family: 'Inter', Fallback, serif;
  color: #9d9d9d;
  font-size: 13px;
  text-align: start;
}

.overlay-div {
  border-radius: 6px;
  border: 1px solid #d9d9d9;
  background: #fbfbfd;
  text-align: start;
  width: 270px;
  position: fixed;
  z-index: 200000;
  padding-bottom: 30px;
  padding-top: 10px;
  padding-right: 10px;
  margin-left: 54px;
}

.padding-left-add-member {
  padding-left: 30px;
}

li {
  font-size: 13px;
}

.capital-letter::first-letter {
  text-transform: uppercase;
}
