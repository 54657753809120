.header-text {
  font-size: 22px;
  font-weight: bold;
  font-family: 'Inter', Fallback, serif;
}

.text_normal {
  font-family: 'Inter', Fallback, serif;
}

.back-button {
}

.back-button:hover {
  cursor: pointer;
}

.post-button {
}

.post-button:hover {
  cursor: pointer;
}

.hover-change-color:hover {
  color: #9d9d9d;
}

@media screen and (max-width: 767px) {
  .mobile_view_padding_right_zero {
    padding-right: 10px;
  }
  .mobile_view_padding_zero {
    padding-left: 0;
    padding-right: 0;
  }
  .mobile_view_text_size {
    font-size: 12px;
  }
  .mobile_view_padding_zero {
    padding-top: 10px;
  }
  .topic_detail_mob {
    text-align: start;
    position: relative;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1300px) {
  .home_stories_ipad_view {
    margin-left: auto;
    margin-right: auto;
  }
  .tab_view_padding_right_zero {
    padding-right: 10px;
  }
}

/* Desktop styles */
@media (min-width: 1301px) {
  /* Your desktop styles here */
  .desktop_view_padding_zero {
    padding-left: 20px;
    padding-right: 20px;
  }
  .desktop_view_padding_right_zero {
    padding-right: 50px;
  }
  .topic_detail_des {
    display: flex;
    flex: 1;
    flex-direction: column;
    text-align: start;
    position: relative;
  }
}
