.flex-style {
  display: flex;
  flex-direction: row;
  border-radius: 15px;
  color: #fbfbfd;
  border-style: solid;
  border-width: thin;
  border-color: #d9d9d9;
  width: 310px;
  height: 29px;
}

.text {
  font-size: 16px;
  color: #fbfbfd;
  font-weight: 700;
}

.button-text {
  color: #fbfbfd;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
