.text_small {
  font-size: 14px;
  font-family: 'Inter', Fallback, serif;
  user-select: none;
}

.text_header {
  font-size: 20px;
  user-select: none;
  font-family: 'Inter', Fallback, serif;
}

.text_header_bold {
  font-size: 20px;
  font-weight: bold;
  user-select: none;
  font-family: 'Inter', Fallback, serif;
}

.react-datepicker-wrapper input[type='text'] {
  border: none;
  background-color: transparent;
  width: 100px;
  font-family: 'Inter', Fallback, serif;
}

.react-datepicker-wrapper:hover input[type='text'] {
  cursor: pointer;
}
