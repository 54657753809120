.container-story {
}

.container-story:hover {
  cursor: pointer;
}

.text {
  font-family: 'Inter', Fallback, serif;
  color: #303655;
  font-size: 12px;
}

@media screen and (max-width: 767px) {
  .story-item-style-mob {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 75px;
    height: 100px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .story-item-style-tab {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 130px;
    margin-right: 2px;
  }
}

/* Desktop styles */
@media (min-width: 1024px) {
  /* Your desktop styles here */
  .story-item-style-des {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 130px;
    margin-right: 2px;
  }
}
