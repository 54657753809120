.main-div-channel-picker {
  display: flex;
  flex-direction: row;

  height: 70px;
  border-top: 0.004em solid #d9d9d9;
  background: #fbfbfd;
  position: relative;
}

.root-div-channel-picker {
  border-radius: 6px;
  padding: 1px;
  border-left: 0.004em solid #d9d9d9;
  border-right: 0.004em solid #d9d9d9;
  border-bottom: 0.004em solid #d9d9d9;
}

.main-div-channel-picker-select {
  display: flex;
  flex-direction: row;
  /*width: 325px;*/
  height: 70px;
  align-items: center;

  /*height: 70px;*/

  background: #fbfbfd;
}

.radio-button-section {
  flex: 20;
  padding-top: 8px;
}

.names-section {
  flex: 50;
  padding-top: 12px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.names-section-name {
  flex: 50;
  padding-top: 19px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /*display: flex;*/
  /*flex-direction: column;*/
  /*align-items: center;*/
  /*background: red;*/
  /*justify-content: flex-start;*/
}

.image-section {
  justify-content: space-evenly;
  flex: 30;
  display: flex;
  align-items: center;
}

.bold {
  color: #000;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
}

.grey {
  color: #9d9d9d;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.image-size-channel-picker {
  width: 35px;
  height: 35px;
  flex-shrink: 0;
  border-radius: 30px;
  border: 1px solid #d9d9d9;
  object-fit: contain;
}

.image-drop-down {
  margin-top: -3px;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}
.flex-row-channel-picker-auto {
  display: flex;
}

.flex-row-channel-picker {
  display: flex;
  align-items: flex-start;

  margin-bottom: -4px;
}

.image-section-selected {
  justify-content: space-evenly;
  flex: 10;
  display: flex;
  align-items: start;
}

@media screen and (max-width: 767px) {
  .margin-phone {
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
}

/* Desktop styles */
@media (min-width: 1024px) {
}
