.text {
  font-family: 'Inter', Fallback, serif;
  color: #303655;
}

@media screen and (max-width: 767px) {
  .home_stories_mobile_view {
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .home_stories_ipad_view {
    margin-left: auto;
    margin-right: auto;
  }
}
