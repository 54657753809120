.select-header {
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contrast-button {
}
.contrast-button:hover {
  cursor: pointer;
  opacity: 0.5;
}

@media screen and (max-width: 767px) {
  .third_div_phone_style {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    width: 100%;
    margin-bottom: -8px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .third_div_tab_style {
    display: flex;
    flex-direction: row;
    margin-top: 35px;
    width: 490px;
  }
}

/* Desktop styles */
@media (min-width: 1024px) {
  /* Your desktop styles here */
  .third_div_desktop_style {
    display: flex;
    flex-direction: row;
    margin-top: 35px;
    width: 490px;
  }
}
