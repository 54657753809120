.user-type-box {
  border-radius: 6px;
  border: 1px solid #d9d9d9;
  background: #d9d9d9;
}

.header-text-style {
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 13px;
  margin-top: 9px;
}

.body-text-style {
  color: #000;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 9px;
  margin-left: 13px;
  margin-right: 13px;
  margin-bottom: 8px;
}

.heading-style {
  color: #000;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.horizontal-line {
  width: 65px;
  height: 0;
  border: 1px ridge #000;
  border-width: 1px;
  margin: 3px;
  display: inline-block;
}
