.c-button {
  font-size: 14px;
  font-weight: bold;
  user-select: none;
  background-color: #d9d9d9;
}

.c-button:hover {
  cursor: pointer;
  background-color: #d0d0d0;
}

@media screen and (max-width: 767px) {
  .button-style-mobile {
    /* padding: 3px; */
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 10px;
    padding-top: 10px;
    border-radius: 20px;
    text-align: center;
    position: relative;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
}

/* Desktop styles */
@media (min-width: 1024px) {
  /* Your desktop styles here */
  .button-style-des {
    position: relative;
    padding: 3px;
    padding-right: 25px;
    padding-left: 25px;
    border-radius: 4px;
  }
}
