.main-div-pem {
  border-radius: 6px;
  background: #fbfbfd;
  margin-top: 100px;
  width: 320px;
  padding: 10px;
}

.main-heading-pem {
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.second-main-div-pem {
  color: #000;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
}

.button-div-pem {
  display: flex;
  justify-content: center;
}

.text-area-placeholder-pem::placeholder {
  color: #9d9d9d;
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;

  line-height: normal;
}

.text-area-pem {
  width: 320px;
  background-color: #d9d9d9;
  border-radius: 6px;
  z-index: 1000;
  border-color: #d9d9d9;
  color: black;
}

@media (min-width: 1024px) {
  /* Your desktop styles here */
}
