.title-header {
  font-size: 20px;
  /* font-family: 'Inter', Fallback, serif; */
  font-weight: bold;
}

.title-normal-pointer:hover {
  cursor: pointer;
}

.link {
  font-size: 10px;
  font-weight: bold;
}

.link:hover {
  color: #767676;
  cursor: pointer;
}

.action-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.action-more-img {
  padding: 2px;
  height: 20px;
  width: 15px;
}

@media screen and (max-width: 767px) {
  .article_font_size {
    font-size: 15px;
  }
  .title-normal-pointer-mobile-view {
    font-size: 14px;
  }
  .title-normal-mobile-view {
    font-size: 10px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
}

/* Desktop styles */
@media (min-width: 1024px) {
  /* Your desktop styles here */
  .title-normal {
    font-size: 1px;
    color: #9d9d9d;
    /* font-family: 'Inter', Fallback, serif; */
  }
}
