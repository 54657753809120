/* input::-webkit-input-placeholder {
  font-style: italic;
  color: #d9d9d9;
}

textarea::placeholder {
  font-style: italic;
  color: grey;
  padding-top: 5px;
  letter-spacing: 0.1px;
}

input,
select,
textarea {
  color: white;
  font-style: normal;
} */

.label-text-input-box {
  color: #000;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 5px;
}
