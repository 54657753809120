/* export const MAX_PHONE_BREAK_POINT = 767
export const MIN_PHONE_BREAK_POINT = 320

export const MAX_TABLET_BREAK_POINT = 1023
export const MIN_TABLET_BREAK_POINT = 768

export const MIN_DESKTOP_BREAK_POINT = 1024 */

/* Desktop styles */
@media (min-width: 1024px) {
  /* Your desktop styles here */
  .home_topic_desktop_view {
    width: 530px;
  }
  .topic-styles-des {
    flex: 1;
    background: #d9d9d9;
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 30px;
    padding-right: 40px;
  }
  .create-post-style-des {
    display: flex;
    border: 1px #d9d9d9 solid;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding-top: 5px;
    padding: 5px;
    padding-right: 40px;
    padding-left: 38px;
  }
}

/* Tablet styles */
@media (min-width: 768px) and (max-width: 1023px) {
  /* Your tablet styles here */
  .home_topic_tablet_view {
    width: 300px;
  }
  .topic-styles-tab {
    flex: 1;
    background: #d9d9d9;
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 40px;
    padding-right: 40px;
  }
  .create-post-style-tab {
    display: flex;
    border: 1px #d9d9d9 solid;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 5px;
    padding-left: 38px;
  }
}

/* Mobile styles */
@media (max-width: 767px) {
  /* Your mobile styles here */
  .home_topic_mobile_view {
    width: 390px;
  }
  .topic-styles-mob {
    flex: 1;
    background: #d9d9d9;
    display: flex;
    flex-direction: column;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 15px;
    padding-right: 10px;
  }
  .create-post-style-mob {
    display: flex;
    border: 1px #d9d9d9 solid;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding-top: 5px;
    padding: 5px;
    padding-right: 40px;
    padding-left: 15px;
  }
}

.topic-container {
}

.topic-container {
  cursor: pointer;
}

.post-button {
}

.post-button:hover {
  cursor: pointer;
}
