.t-button {
  font-size: 14px;
  font-weight: bold;
  user-select: none;
  border: 2px solid #000000;
  border-radius: 20px;
}

.t-button:hover {
  cursor: pointer;
  background-color: #d0d0d0;
}
