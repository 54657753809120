/* input::-webkit-input-placeholder {
  font-style: italic;
  color: #d9d9d9;
}

textarea::placeholder {
  font-style: italic;
  color: grey;
  padding-top: 5px;
  letter-spacing: 0.1px;
}

input,
select,
textarea {
  color: white;
  font-style: normal;
} */

.label-text-input-box-drop-down-non-location {
  color: #000;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 7px;
}

.drop-down-list-non-location {
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  width: 100%;
  max-width: 290px;
  margin-left: 2px;
  border-right-color: #d9d9d9;
  border-left-color: #d9d9d9;
  border-bottom-color: #d9d9d9;
  background: white;
  border-top-color: white;
  border-style: solid;
  margin-top: 0px;
  max-height: 100px;
  overflow-x: scroll;
  position: absolute;
  z-index: 10000;
}

.list-item-non-location:hover {
  background: #d9d9d9;
}

.list-align-non-location {
  text-align: start;
  font-size: 14px;
  padding: 5px 0 5px 10px;
  border-style: solid;
  border-top-color: #d9d9d9;
  border-bottom-color: #d9d9d9;
  border-width: 1px;
  border-left-color: white;
  border-right-color: white;
}

#icon {
  position: absolute;
  right: 12px;
  top: 8px;
  width: 24px;
  height: 24px;
}
