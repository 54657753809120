@media screen and (max-width: 767px) {
  .main-dev-mobile {
    position: fixed;
    top: 30%;
    left: 0;
    right: 0;
  }
  .input-width-mobile {
    width: 80%;
  }
  .button-width-mobile {
    width: 72%;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .main-dev-tab {
    position: fixed;
    top: 30%;
    left: 30%;
    right: 30%;
  }
  .input-width-tab {
    width: 100%;
  }
  .button-width-tab {
    width: 91%;
  }
}

/* Desktop styles */
@media (min-width: 1024px) {
  /* Your desktop styles here */
  .main-dev-des {
    position: fixed;
    top: 40%;
    left: auto;
    right: auto;
  }
  .input-width-des {
    width: 0%;
  }
  .button-width-des {
    width: 36%;
  }
}
