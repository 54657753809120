.main-div-create-org {
  background: #fbfbfd;
}

.margin-top-profile-screen {
  margin-top: 21px;
}

.margin-top-inputs {
  margin-top: 37px;
}

.check-text-create-org {
  color: #000;
  text-align: center;
  font-family: 'Inter';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  flex-shrink: 0;
}

.avatar-text-width-profile-screen {
  width: 73px;
}

.check-text-margin-top {
  margin-top: 14px;
}

.text {
  font-size: 16px;
  color: #fbfbfd;
  font-weight: 700;
  line-height: 19.36px;
}

.text-left {
  text-align: left;
}

.margin-top-button-section {
  margin-top: 40px;
}

.middle-flex-profile {
  width: 300px;
}

.on-hover-organization:hover {
  background: #d9d9d9;
}

.inputCss::placeholder {
  font-size: 11px; /* Set the desired font size */
  font-family: Inter;
  font-style: italic;
  font-weight: 400;
  line-height: 13.31px;
  text-align: left;

}

.inputList::placeholder{
  font-size: 11px; /* Set the desired font size */
  font-family: Inter;
  font-style: italic;
  font-weight: 400;
  line-height: 13.31px;
}

.text-area-placeholder::placeholder{
  font-size: 11px; /* Set the desired font size */
  font-family: Inter;
  font-style: italic;
  font-weight: 400;
  line-height: 13.31px;
}