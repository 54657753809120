.text-name-member {
  max-width: 250px;

  color: #000;

  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.first-letter::first-letter {
  text-transform: uppercase;
}

.text-role-members-profile {
  color: #9d9d9d;
  text-align: right;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-right: 10px;
}

.text-email {
  color: #9d9d9d;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.image-fit {
  object-fit: contain;
}
