.main-div-user-details {
  background: #fff;
  padding-top: 15px;
  overflow-x: hidden;
  width: 347px;
  margin-right: auto;
  margin-left: auto;
}

.check-text-user-details {
  color: black;
  text-align: center;
  font-family: 'Inter';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}

.logo-user-details {
  margin-top: 20px;
}

.image-size-user-details {
  object-fit: contain;
  height: 200px;
  width: 200px;
  border-radius: 200px;
  border: 1px solid #9d9d9d;
}

.edit-pencil-user-details {
  height: 24px;
  width: 24px;
}

@media screen and (max-width: 400px) {
  .user-details-margin-mobile {
    margin-right: 10px;
  }
}

@media only screen and (min-device-width: 401px) and (max-device-width: 1024px) {
  .user-details-margin-tab {
    margin-left: auto;
    margin-right: auto;
  }
}

/* Desktop styles */
@media (min-width: 1024px) {
  .user-details-margin-desktop {
    margin-left: auto;
    margin-right: auto;
  }
}
