.main-div-profile-screen {
  background: #303655;
}

.margin-top-profile-screen {
  margin-top: 21px;
}

.margin-top-inputs {
  margin-top: 37px;
}

.check-text-profile-screen {
  color: #fbfbfd;
  text-align: center;
  font-family: 'Inter';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  flex-shrink: 0;
}

.avatar-text-width-profile-screen {
  width: 73px;
}

.check-text-margin-top {
  margin-top: 14px;
}

.text {
  font-size: 16px;
  color: #fbfbfd;
  font-weight: 700;
  line-height: 19.36px;
}

.text-left {
  text-align: left;
}

.margin-top-button-section {
  margin-top: 40px;
}

.middle-flex-profile {
  width: 300px;
}
