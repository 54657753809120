.title-header {
  font-size: 20px;
  font-family: 'Inter', Fallback, serif;
  font-weight: bold;
}

.title-normal_dark {
  font-size: 16px;
  color: #000000;
  font-family: 'Inter', Fallback, serif;
}

.title-normal {
  font-size: 16px;
  color: #9d9d9d;
  font-family: 'Inter', Fallback, serif;
}

.nav-button {
  height: 20px;
  width: 20px;
}
