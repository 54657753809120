.menu-container {
  background: #fbfbfd;
  padding: 5px;
  border-radius: 6px;
  border: #9d9d9d solid 1px;
  text-align: start;
}

.menu-item-container {
  border-radius: 4px;
  padding: 7px;
}

.menu-item-container:hover {
  cursor: pointer;
  background: #ededed;
  user-select: none;
}
