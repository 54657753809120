.main-div-accept-screen {
  background: #303655;
}

.margin-top-accept-screen {
  margin-top: 21px;
}

.margin-top-inputs-accept-screen {
  margin-top: 37px;
}

.check-text-accept-screen {
  color: #fbfbfd;
  text-align: center;
  font-family: 'Inter';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  flex-shrink: 0;
}

.text-left-accept-screen {
  text-align: left;
}

.middle-flex-accept-screen {
  width: 300px;
}
